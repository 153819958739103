import React, { useEffect, useRef } from 'react'
import MessengerCustomerChat from 'react-messenger-customer-chat';

export default function MessengerChat() {


  return (
    <div>
      <MessengerCustomerChat
        pageId="102840828292776"
        appId="361765999937307"
      />
    </div>
  )
}
