// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#BottomNav_menu__M9d7F li:hover {
    color: #9b1620;
}`, "",{"version":3,"sources":["webpack://./src/Shared/PoftfolioNav/BottomNav.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB","sourcesContent":["#menu li:hover {\n    color: #9b1620;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": `BottomNav_menu__M9d7F`
};
export default ___CSS_LOADER_EXPORT___;
