import React from 'react'
import './Spinner.css'

export default function Spinner() {
    return (
        // <div className='loader_total'>
        //     <div className='loader_container'><div className="loader">Loading...</div></div>
        // </div>
        <div className='spinner-total'>
            <div class="lds-hourglass"></div>
        </div>
    )
}