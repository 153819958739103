// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets//bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

#Home_second__lg018 {
    background: linear-gradient(#F4EBEC,#FCFAFA);
}

.Home_second_banner__r9Nx3 {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
}

@media (max-width: 1600px) {
    #Home_second_info__njgfI {
        font-size: 16px;
    }
}

@media (max-width: 1300px) {
    #Home_second_header__v7\\+5q{
        font-size: 30px;
    }

    #Home_second_image__Cl3r6{
        width: 369px;
        height: 330px;
    }
}

/* @media (max-width: 768px) {
    #second_header{
        font-size: 3.5vw;
    }

    #second_info {
      font-size: 13px;
    }
} */

@media (max-width: 1024px) {
    #Home_second_container__zVh4F{
        display: grid;
        grid-template-columns: repeat(1,  1fr);
    }

    #Home_second_header__v7\\+5q{
        font-size: 4.5vw;
    }

    #Home_second_info__njgfI {
      font-size: 13px;
    }
    #Home_second_left__bSW0h {
        text-align: center;
        padding: 20px;

    }

}


`, "",{"version":3,"sources":["webpack://./src/Pages/Home/Home.module.css"],"names":[],"mappings":";;AAEA;IACI,4CAA4C;AAChD;;AAEA;IACI,oEAAwD;AAC5D;;AAEA;IACI;QACI,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,YAAY;QACZ,aAAa;IACjB;AACJ;;AAEA;;;;;;;;GAQG;;AAEH;IACI;QACI,aAAa;QACb,sCAAsC;IAC1C;;IAEA;QACI,gBAAgB;IACpB;;IAEA;MACE,eAAe;IACjB;IACA;QACI,kBAAkB;QAClB,aAAa;;IAEjB;;AAEJ","sourcesContent":["\n\n#second {\n    background: linear-gradient(#F4EBEC,#FCFAFA);\n}\n\n.second_banner {\n    background: url(\"../../assets//bg.png\") no-repeat center;\n}\n\n@media (max-width: 1600px) {\n    #second_info {\n        font-size: 16px;\n    }\n}\n\n@media (max-width: 1300px) {\n    #second_header{\n        font-size: 30px;\n    }\n\n    #second_image{\n        width: 369px;\n        height: 330px;\n    }\n}\n\n/* @media (max-width: 768px) {\n    #second_header{\n        font-size: 3.5vw;\n    }\n\n    #second_info {\n      font-size: 13px;\n    }\n} */\n\n@media (max-width: 1024px) {\n    #second_container{\n        display: grid;\n        grid-template-columns: repeat(1,  1fr);\n    }\n\n    #second_header{\n        font-size: 4.5vw;\n    }\n\n    #second_info {\n      font-size: 13px;\n    }\n    #second_left {\n        text-align: center;\n        padding: 20px;\n\n    }\n\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"second": `Home_second__lg018`,
	"second_banner": `Home_second_banner__r9Nx3`,
	"second_info": `Home_second_info__njgfI`,
	"second_header": `Home_second_header__v7+5q`,
	"second_image": `Home_second_image__Cl3r6`,
	"second_container": `Home_second_container__zVh4F`,
	"second_left": `Home_second_left__bSW0h`
};
export default ___CSS_LOADER_EXPORT___;
