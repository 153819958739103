// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
    #TopNav_top_nav__ynMyu {
        display: none;
    } 
}

`, "",{"version":3,"sources":["webpack://./src/Shared/TopNav/TopNav.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":["@media (max-width: 768px) {\n    #top_nav {\n        display: none;\n    } \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top_nav": `TopNav_top_nav__ynMyu`
};
export default ___CSS_LOADER_EXPORT___;
