// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
 .signup {
        width: 35%;
        margin: 0 auto;
    }


@media screen and (max-width: 500px) {
    .signup {
        width: 95%;
        margin: 0 auto;
    }
}

`, "",{"version":3,"sources":["webpack://./src/Pages/SignUp/SignUp.css"],"names":[],"mappings":";CACC;QACO,UAAU;QACV,cAAc;IAClB;;;AAGJ;IACI;QACI,UAAU;QACV,cAAc;IAClB;AACJ","sourcesContent":["\n .signup {\n        width: 35%;\n        margin: 0 auto;\n    }\n\n\n@media screen and (max-width: 500px) {\n    .signup {\n        width: 95%;\n        margin: 0 auto;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
